.app {
  padding: 30px;
  background-color: rgb(147, 146, 144);
  height:100vh;
}

.todo {
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation .4s forwards;
  position:relative;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    bottom:-10px;
    
  }
  to {
    opacity: 1;
    bottom:0;
  }
}



@keyframes fadeOut {
  from {
    opacity: 1;
    left:0;
  }
  to {
    opacity: 0;
    left:100px;
  }
}

.fade-out {
  animation: fadeOut 0.3s ease;
}